<template>
  <div class="about">
  </div>
</template>

<script>
var momentTZ = require('moment-timezone');
import { HTTP, HOME, fwAPI, userAPI, myTimer, receiveToken, myName, getDomainPrefix} from '../variables.js';
import store from "@/store";
import JQuery from "jquery";
import QrcodeVue from 'qrcode.vue'
let $ = JQuery;

export default {
   store,
   data () {
    return {
     url: "",
     version: "",
     builtDate: "",
     myTimer,
     history: [],
     user: {},
     chPWStyle: "",
     pwChangeDisabled: false,
     policy: [],
     token: ""
    }
  },
  components : {
    //QrcodeVue
  },
  methods:
  {
	doLogout() {
        this.$router.replace("/logout");
      },
      appName(x) { return myName; },
      identity(x) { return x; },
      changePW() {
        this.$router.replace("/setPasswd");
        },
      showFavs() {
      	this.$router.replace("/fw/myDashBoard");
      },
      showAccessPoint() {
        this.$router.replace("/showAccessPoint/"+sessionStorage.accessPointId);
        //this.$router.showTenant;
        },
      showUsers() {
        this.$router.replace("/users");
        //this.$router.showTenant;
        },
       onLogin(tenantId)
       {
         
        //
        // CALLBACK function, 
        // enter here all calls after userId, login and tenantId are stored in the sessionStorage
        //
		this.token = sessionStorage.token;
		this.login = sessionStorage.login;
		this.tenant = sessionStorage.tenant;
		this.version = sessionStorage.serviceVersion;
		this.builtDate = sessionStorage.builtDate;
		$("#user").html( sessionStorage.login );
		$("#tenant").html( sessionStorage.tenant );
		
		this.$emit('login', tenantId);
		if ( sessionStorage.firstScreen === 'dataMiner')
		{
			this.$router.replace('/dataMiner')
		}
		else
		{
		 	this.$router.replace("/fw/myDashBoard");
		}
       },
       getLogin() { return sessionStorage.login; },
       getTenant() { return sessionStorage.tenant; },
       customFormatter(date) {
		return momentTZ(date).tz(sessionStorage.timezone).format(sessionStorage.dateFormat);
		},
       printChange(h) {
            var fmt = sessionStorage.dateFormat+ " " + sessionStorage.timeFormat;
            var time = "";
	        try
	        {
	            time = momentTZ(new Date(h.lUpdate)).tz(sessionStorage.timezone).format(fmt);
	        }
	        catch ( e) {
	        //
	        }
       		if ( h.changes.indexOf("lastLogin") >= 0)
      		{
      			return "Login: " + time + " from " + h.ip;
      		}
      		else
      		{
      			return "Password change: " + time + " from " + h.ip;
      		}
       },
       getHistory() {
       	    let that = this;
       	    //alert(userAPI+"/getLoginHistory/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.login)
       	    //alert(userAPI+"/getLoginHistory/"+sessionStorage.tenantId+"/"+sessionStorage.userId);
       	    //console.log(userAPI+"/getLoginHistory/"+sessionStorage.tenantId+"/"+sessionStorage.userId);
			HTTP.post( userAPI+"/getLoginHistory/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId) 
            .then( response => 
               { 
              	 that.history = new Array();
              	 for ( var h in response.data )
              	 {
              	 	let hist = response.data[h];
              	 	
              	 	that.history.push( hist);
              	 	if ( that.history.length > 10 )
              	 	{
              	 		break;
              	 	}
              	 }
               }).catch(e => {
		                     that.$toast.error(e.response.data, 'Error', { position: "topRight" });
		      });
		      
		    HTTP.post( userAPI+"/getById/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.login+"/"+sessionStorage.userId)
	            .then( response => 
	            { 
	                that.user = response.data;
	                
	                HTTP.get( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/PasswordPolicy/allEntities/true/0") 
		            .then( response => 
		               { 
		              	 let array= response.data.data;
		              	 that.policy = new Array();
		              	 that.pwChangeDisabled = false;
		              	 for ( var i in array)
		              	 {
		              	 	let rule = array[i];
		              	 	if ( rule.ruleValue === 'Expiration' )
		              	 	{
		              	 		that.policy.push( rule);
		              	 		if ( rule.ruletypeValue === 'Maximum' )
		              	 		{
		              	 			let start = that.user.pwlUpdate;
		              	 			let now = new Date().getTime();
		              	 			let max = rule.value * 24 * 3600 * 1000;
		              	 			
		              	 			//alert( start+"-"+ max + "::" + (( now - start )));
		              	 			if ( ( now - start ) > max )
		              	 			{
		              	 				that.chPWStyle = 'background-color: #8d8; font-weight: bold;';
		              	 			}
		              	 		}
		              	 		if ( rule.ruletypeValue === 'Minimum' )
		              	 		{
		              	 			let start = that.user.pwlUpdate;
		              	 			let now = new Date().getTime();
		              	 			let min = rule.value * 24 * 3600 * 1000;
		              	 			
		              	 			//alert( start+"-"+ max + "::" + (( now - start )));
		              	 			if ( ( now - start ) < min )
		              	 			{
		              	 				that.pwChangeDisabled = true;
		              	 			}
		              	 		}
		              	 	}
		              	 	setTimeout( function() { that.$router.replace("/fw/myDashBoard")}, 1000);
		              	 }

		                 
		               }).catch(e => {

		                     that.$toast.error("X1 "+e.response.data, 'Error', { position: "topRight" });
		             });
	                
	            }).catch(e2 => {

	            	   that.$toast.error("X2" + e2.response.data, 'Error', { position: "topRight" });
					   
	            });
       }
  },
  computed: {
  },
  created()
  {
	receiveToken( this.$route.params.token, this.onLogin ); 	
	
  }


}
</script>
<style lang="scss" scoped>
  .org-description {
    margin-top: 50px;
  }
        
</style>